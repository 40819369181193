@import '../components/TableExtended/style.css';
@import '../components/Modules/PaymentMethodConfiguration/style.css';
@import './spinner.css';

:root {	
	--backgroundColor: #f7f7f7;
	--backgroundGray: #f8f8fa;
	--primaryColor: rgb(4, 34, 136);
	--primaryColorLight: rgb(27, 82, 146);
	--textColorSecondary: rgba(0, 0, 0, 0.45);
	--textTitleColorPrimary: rgb(4, 34, 136);
	--textTitleColorPrimaryLight: #1c7aff;

	--primaryFont: SFProText-Regular;
	--defaultFontSize: .875rem; /* 14px */
	--defaultButtonPrimaryColor: rgb(45, 112, 227);
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.ant-picker-cell-selected .ant-picker-cell-inner {
	background-color: var(--defaultButtonPrimaryColor) !important;	
}

.ant-btn.ant-btn-primary:not([disabled]):not(.ant-btn-dangerous) {
	background-color: var(--defaultButtonPrimaryColor);
	border-color: var(--defaultButtonPrimaryColor);
	color: white;
	&:hover {
		border-color: #5694f0;
		background: #5694f0;
	}
}
.ant-picker-time-panel {
	width: max-content;
}

.text-primary-font {
	font-family: var(--primaryFont);
}

.text-primary-color {
	color: var(--primaryColor);
}

.primary-color-bg {
	background-color: var(--primaryColor);
	color: white;
}


.page-content-spinner-container {
	width: 100%;
	height: 100%;
}
/**
  available variables:
    --formMainContainer_fontSize
    --formMainContainer_gridTemplateColumn (default is 12)
    --formMainContainer_labelColSpan

*/

.FormMainContainer.item-gap-attr-default,
.FormMainContainer.item-gap-attr-10 {
	& .ant-form-item {
		margin-bottom: 10px;
	}
}

.FormMainContainer.ant-form-vertical {
	&.ant-form-hide-required-mark {
		& .ant-form-item.required .ant-form-item-label:has(.ant-form-item-required) label::after {
			visibility: visible;
		}
	}
	& .ant-form-item.required .ant-form-item-label:not(:has(.ant-form-item-required))::after {
		content: ' *';
		color: red;
		font-size: 1.135rem;
	}
	& .mock-form-item-wrapper .ant-form-item-label:has(label.required) {
		&::after {
			content: ' *' !important;
			color: red;
			font-size: 1.135rem;
		}
	}
}

.FormMainContainer.ant-form-hide-required-mark {
	& label.ant-form-item-required::after {
		content: ' *';
		color: red;
		margin-left: 0.3rem;
		font-size: 1.135rem;
		display: block;
	}
}

.FormMainContainer.ant-form-horizontal {
	& .ant-form-item.required .ant-form-item-label label::after {
		content: ' *';
		color: red;
		margin-left: 5px;
		font-size: 1.135rem;
	}
}

.FormMainContainer {
	--borderRadius: 0.25rem;
	--errorColor: rgb(239, 68, 68);
	& .ant-form-item label {
		font-size: var(--defaultFontSize);
	}
	& .ant-form-item:not(:has(.ant-form-item)) {
		/* margin-bottom: 0px; */

		& .ant-form-item-control-input-content {
			& * {
				/* border-radius: var(--borderRadius); */

				& .ant-radio-wrapper .ant-radio * {
					border-radius: 9999px !important;
				}
			}
		}

		& .ant-form-item-label {
			padding-bottom: 2px;
		}

		& .ant-input-number-group {
			&:has(.ant-input-number-status-error) {				
				& > div.ant-input-number-group-addon {
					border-color: red !important;					
				}
			}
			&:last-child:has(.ant-input-number-group-addon) {
				& .ant-input-number-affix-wrapper {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
	
				& .ant-input-number-group-addon {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}	

	button[type='submit'] {
		padding-inline: 1.4rem;
	}

	& .default-form {
		& .ant-form-item.required .ant-form-item-label label::after {
			content: ' *';
			color: red;
			margin-left: 5px;
			font-size: 1.135rem;
		}

		& .ant-form-item {
			margin-bottom: 0 !important;

			& .ant-row.ant-form-item-row {
				border-top-width: 0;
				border-right-width: 1px;
				border-left-width: 1px;
				border-bottom-width: 1px;
				border-color: #f0f0f0;
				border-style: solid;
				background-color: var(--primaryColor);

				&.show-border-top {
					border-top-width: 1px;;
				}
				
				&:not(.vertical-layout-row,:has(.ant-col-24)) {
					display: grid;
					grid-template-columns: repeat(12, minmax(0, 1fr));
					& .ant-form-item-label {
						grid-column: span 5;
						display: inline-flex;
						max-width: unset;
					}
					& .ant-form-item-control {
						grid-column: span 7;
					}
				}

				& .ant-form-item-label {					
					text-align: left;
					padding: 0.7rem;
					align-items: center;

					& label {
						color: white;
						font-weight: 500;
						/* letter-spacing: 0.05rem; */
						white-space: normal;
					}
				}

				& .ant-form-item-control {
					background-color: white !important;
					padding-inline: 0.7rem;
					padding-block: 0.7rem;

					& .ant-form-item-control-input {
						& .ant-picker {
							width: 100% !important;
						}
					}

					& .ant-form-item-explain-error {
						font-weight: 400;
					}

					& .ant-input-number-wrapper.ant-input-number-group {
						& > div {
							border-radius: 0 !important;
						}

						& :first-child {
							border-top-left-radius: var(--borderRadius) !important;
							border-bottom-left-radius: var(--borderRadius) !important;
						}

						& :last-child {
							border-top-right-radius: var(--borderRadius) !important;
							border-bottom-right-radius: var(--borderRadius) !important;
						}
					}
				}
			}
			&.display-only {
				& > .ant-row.ant-form-item-row {
					grid-template-columns: repeat(1, minmax(0, 1fr));
				}
			}
		}

		& .ant-form-item.has-top-border .ant-form-item-row {
			border-top-width: 1px !important;
		}

		& .ant-form-item .ant-form-item-row:has(span.form-display-text-field) {
			height: 100% !important;
			& .ant-form-item-control {
				display: flex !important;
				justify-content: center !important;
			}
		}

		&.self-grid-cols {
			& .ant-row.ant-form-item-row {
				display: flex;
				grid-template-columns: unset;
			}
			& .ant-form-item-label,
			.ant-form-item-control {
				grid-column: unset;
			}
		}
	}

	& .default-form .ant-form-item:first-child .ant-row.ant-form-item-row {
		border-top-width: 1px;
	}

	& .is-select-field-error,
	.is-select-field-error:not(:focus),
	.is-select-field-error:not(:hover),
	.is-select-field-error:not(:active) {
		border: 1px solid red !important;
		border-radius: 0.25rem;
	}

	.ant-modal-body & {
		padding: 0px !important;
		& .modal-form-footer {
			border-top: 4px solid gray;
			padding: -4rem !important;
		}
	}

	.form-field-wrapper:has(.form-field-wrapper-error) {
		& .ant-input-affix-wrapper {
			border: 1px solid var(--errorColor);
		}
		> button {
			border: 1px solid var(--errorColor);
		}
		.form-field-wrapper-error {
			color: var(--errorColor);
			font-size: 0.875rem /* 14px */;
			line-height: 1.25rem /* 20px */;
			word-wrap: break-word;
			overflow-wrap: break-word;
			white-space: pre-wrap;
			font-weight: 400;
			
		}
		> .ant-select:not(.ant-pagination-options-size-changer) {
			border: 1px solid var(--errorColor);
			border-radius: .5rem;
			& .ant-select-selector {
				border: 0px;
			}
		}
	}
	/* remove some nested unnecessary style */
	& .ant-select.ant-select-status-error.ant-pagination-options-size-changer {
		& .ant-select-selector {
			border-color: rgb(217, 217, 217) !important;
			&:hover {
				border-color: rgb(64, 150, 255) !important;
			}
		}	
	}
}

.FormMainContainer.ant-collapse {
	& .ant-collapse-item {
		& .ant-collapse-header {
			border: 1.5px solid var(--primaryColor);
			border-radius: 0.4rem !important;

			& .ant-collapse-header-text {
				color: var(--primaryColor);
				font-weight: 600;
			}

			& span.anticon svg {
				color: var(--primaryColor);
			}
		}

		& .ant-collapse-content .ant-collapse-content-box {
			padding-inline: 2px !important;
		}
	}
}

/** Align button icon because we use react-icons */
.ant-btn.custom-default-btn {	
	/* border-radius: 0.33rem; */
	&:is(a) {
		padding-block: 0;
		display: flex;
		align-items: center;
		justify-content: center;				
	}	
	
	&:has(> .ant-btn-icon) {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;	
		
	}
	&:not(.square,.ant-btn-sm) {
		min-height: 2rem;
	}
	&.ant-btn-primary:not(:disabled):not(.ant-btn-dangerous) {
		background-color: var(--defaultButtonPrimaryColor);
		border-color: var(--defaultButtonPrimaryColor);
		&:hover {
			opacity: 0.84;
		}
	}
	&:is(.btn-variant-secondary) {
		&.ant-btn-primary {
			background-color: mediumslateblue !important;
			&.ant-btn-loading {
				color: white;
			}
		}
		&.ant-btn-dashed:hover {
			color: mediumslateblue;			
		}
		border-color: mediumslateblue !important;		
	}
	&:is(.btn-variant-confirm) {
		&.ant-btn-primary {
			background-color: green !important;			
		}
		&.ant-btn-dashed:hover {
			color: green;			
		}
		border-color: green !important;		
	}
	&:is(.btn-variant-link) {
		border-color: rgb(18, 67, 226) !important;
		&:hover {
			font-weight: 600;
		}
	}	
	&:is(.btn-variant-light) {
		&.ant-btn-primary {
			background-color: var(--primaryColorLight) !important;
			&.ant-btn-loading {
				color: white;
			}
		}
		&.ant-btn-dashed:hover {
			color: var(--primaryColorLight);			
		}
		border-color: var(--primaryColorLight) !important;		
	}
}
.btn-action-description {
	& button {
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
	}
	& .show-desc-trigger {
		cursor: pointer;
		background-color: var(--defaultButtonPrimaryColor);
		& svg {
			color: white;
		}
		border-bottom-right-radius: 6px;
		border-top-right-radius: 6px;
		border-left: .5px dashed white;
		padding-inline: 0.5rem;
		&:hover {
			opacity: 0.8;
			& svg {
				transform: scale(1.1);
				transition-duration: 250ms;
			}
		}
	}	
}
.btn-action-description-popover {
	& .ant-popover-content .ant-popover-inner {
		padding: 0px;
		max-width: 300px;
		& .ant-popover-title {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			padding-inline: 12px;
			padding-block: 6px;
			background-color: var(--defaultButtonPrimaryColor);
			color: white;
		}
		& .ant-popover-inner-content {
			padding-inline: 12px;
			padding-bottom: 12px;
		}
	}
}

.ant-btn-group {
	& .custom-default-btn.ant-btn:not(:first-child),
	.custom-default-btn.ant-btn:not(:last-child) {
		border-radius: 0rem;
	}

	& > :first-child {
		border-top-left-radius: 0.4rem !important;
		border-bottom-left-radius: 0.4rem !important;
	}

	& > :last-child {
		border-top-right-radius: 0.4rem !important;
		border-bottom-right-radius: 0.4rem !important;
	}
}

label.required::after {
	content: '*';
	margin-left: 5px;
	color: red;
	font-size: 1.135rem;
}

.ant-input-affix-wrapper .ant-input-suffix .ant-input-clear-icon {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	& svg {
		width: 0.8rem;
		height: 0.8rem;
	}
}

.ant-select-selection-item {
	/* white-space: pre-wrap !important; */
	overflow: hidden;
	white-space: nowrap !important;
	text-overflow: ellipsis;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none !important;
}

.hide-scrollbar::-webkit-scrollbar-track {
	display: none !important;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
	display: none !important;
}

.ant-input-number.number-align-right:not(.number-has-control), .ant-input-number-group-wrapper.number-align-right:not(.number-has-control) {
	& input {
		text-align: right;
		padding-right: 0.8rem;
	}
}

.ant-input-number.number-align-right.number-has-control, .ant-input-number-group-wrapper.number-align-right.number-has-control {
	input {
		padding-right: 0.8rem;
		/* transition: padding-right 100ms; */
	}

	input:hover {
		padding-right: 1.6rem;
		/* transition: padding-right 100ms; */
	}

	input:active {
		padding-right: 1.6rem;
		/* transition: padding-right 100ms; */
	}

	input:focus {
		padding-right: 1.6rem;
		/* transition: padding-right 100ms; */
	}
}


/* .ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
} */

.ant-picker.datepicker {
	&.datepicker-input-readonly {
		background-color: transparent !important;
		cursor: default !important;
		& input {
			cursor: default !important;
			&:disabled {
				color: rgb(20, 20, 20) !important;
			}
		}
	}
}

.date-text-align-right {
	& input {
		text-align: right;
		vertical-align: middle;	
		min-width: 130px !important;		
	}
	& .ant-picker-input:hover input {
		&:not(:placeholder-shown){
			padding-right: 1.25rem;
		}
	}
	& span.ant-picker-suffix {
		margin-left: 1px;
		margin-right: 10px;
		order: -1;
	}
}

.default-container.v2 {
	display: flex;
	gap: 0.75rem;
	padding: 1rem;
	border-left: 6px solid rgb(4, 2, 128);
	border-block: 1px solid rgb(235, 235, 235);
}
.default-container.filter-component {
	--borderRadius: 0.25rem;
	width: 100%;
	border: 0;
	border-left-width: 6px;
	border-style: solid;
	border-left-color: rgb(30, 64, 175);
	padding-block: 16px;
	padding-inline: 20px;
	box-shadow: 5px 5px 2px rgb(229, 231, 235);
	/* margin-bottom: 25px; */
	margin-top: 15px;

	& .filter-items-container {
		& .filter-item-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			& label {
				color: var(--primaryColor);
			}
			
			&:has(.error-text-placeholder) {
				& .ant-select-selector {
					border: 1px solid red;
				}
			}
		}
	}

	/* & .filter-item-content {
		& .ant-input-affix-wrapper {
			border-radius: var(--borderRadius) !important;
		}
	} */	
}

.as-text:has(input), .datepicker-as-text:has(input) {
	background-color: transparent !important;

	& input {
		/* color: rgba(30, 30, 30, 0.85) !important; */
		color: inherit !important;
		&:hover {
			background-color: transparent !important;
		}
	}
	&.datepicker-as-text {		
		padding-left: 0px;
		cursor: default !important;		
		& input {
			cursor: text !important;
			color: inherit !important;
			&:disabled {
				color: rgb(20, 20, 20) !important;
			}
		}
	}
}

.ant-picker-cell:has(.custom-disable-date-datepicker) {
	color: rgb(223, 223, 223);
	background-color: rgb(245, 245, 245);
	border: 1px solid white;
}

/* for input that has search icon addOnAfter */
.ant-input-group-wrapper.search-input {
	& .ant-input-group-addon {
		& button {
			background-color: var(--primaryColor);
			border-top-right-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;
		}
		border-top-right-radius: 0.25rem;
		border-bottom-right-radius: 0.25rem;
		& svg {
			color: white;
			stroke-width: 2rem;
		}
		&:hover {
			opacity: 0.8;
			& svg {
				scale: 1.1;
			}
		}
	}
}
/* button svg {
	stroke-width: 1.5rem;
	width: 1rem;
	height: 1rem;
} */

.ant-modal-content .ant-modal-header {
	padding-bottom: 1.2rem;
}

table.native-default-table {
	& > thead {
		& tr {
			& th {
				padding: 0.7rem;
				padding-block: 1rem;
				border: 1px solid rgba(240, 240, 240, 1);
				text-align: left;
				font-weight: 500;
				background-color: var(--primaryColor);
				color: white;
				&:first-child {
					border-top-left-radius: 0.6rem;
					border: 0;
				}
				&:last-child {
					border-top-right-radius: 0.6rem;
					border: 0;
				}
				&.required::after {
					content: '*';
					margin-left: 5px;
					font-size: 1.2rem;
					color: red;
				}
			}
		}
	}
	& > tbody {
		& > tr {
			& > td {
				padding: 0.8rem;
				border: 1px solid rgba(240, 240, 240, 1);
			}
		}
	}
}

.closing-announcement-alert {
	border-radius: 0;
}
.required-asterisk::after {
	content: "*";
	color: red;
	margin-left: .5rem;
	font-size: 1.1rem;
}

.dense {
	& .ant-modal-header {
		padding-bottom: 0px;
	}
}

.ant-collapse {
	& .ant-collapse-item.header-flex-center {
		& .ant-collapse-header {
			align-items: center;
		}
	}
}

.input-number-range {
	border: 1px solid rgba(210,220,210,.7);
	position: relative;
	border-radius: 0.35rem;	
	& .input-number-separator svg {
		color: gray;
	}
	& .clear-icon {
		position: absolute;
		right: 0;
		margin-right: 5px;
		& svg {
			color: gray;
		}
	}
	&:not(:hover) {
		& .clear-icon {
			display: none;
		}
	}
}
/* if input number add on after is button */
.ant-input-number-group-addon:is(:last-child):has(button) {
	padding: 0px !important;
	& button {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		border: 0px;
	}
}

.select-with-prefix-display-popup {
	& .ant-select-item.ant-select-item-option {
		border-bottom: 1px solid rgba(210,220,210,.7);
		&:has(.is-selected) {
			background: rgb(230,247,255);
			& span {
				font-weight: 600 !important;
			}
		}
	}
}


.select-with-prefix-display {	
	& > .ant-select-selector {
		& > .ant-select-selection-item {
			overflow: hidden !important;
			white-space: nowrap !important;
			text-overflow: ellipsis !important;
		}
	}
	& > .ant-select-selector > .ant-select-selection-overflow > .ant-select-selection-overflow-item {		
		& span.ant-tag {
			display: flex;
			& .selector-tag-text-display {
				overflow: hidden;			
				word-wrap: break-word;
				overflow-wrap: break-word;
				white-space: pre-wrap;
			}
		}
	}
}

.page-content-spinner-container {
	background-color: rgba(210,210,210,.4);
}
.ant-modal:has(.search-condition-filter-body) {
	width: max-content !important;
	max-width: 1000px;
	& .search-condition-filter-body {
		align-items: flex-start;		
	}
	& .ant-modal-footer {
		margin-top: 2rem;
	}
}
.input-number-as-text input.ant-input-number-input {
	padding-inline: 0px !important;
}
.as-text:has(input.ant-input) {
	padding-inline: 0px;
	
}
.amount-summary-by-group {
	& span.hidden {
		display: unset !important;
		font-size: .8rem;
		color: gray;
		opacity: .3;
	}
	& .detail-popover-icon:not(.ant-popover-open):hover {
		scale: 1.1;
		transition-duration: 500ms;
	}
}
.amount-summary-by-group-popover {
	min-width: 300px;
	max-width: 500px;
	& .FormMainContainer .default-form {
		& .ant-row.ant-form-item-row {		
			display: grid;
			grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
			& .ant-form-item-label {
				grid-column: span 2 !important;			
			}
			& .ant-form-item-control {
				grid-column: span 2 !important;
				display: flex;
				align-items: center;				
			}			
		}
	}
}
.input-number-display-text {
	display: block;
	&.positive-sign {		
		color: rgba(0, 128, 0, 1);			
		&.positive {
			color: rgba(0, 128, 0, 1);			
		}	
		& > .currency-symbol {
			color: rgba(0, 128, 0, 1);
		}	
		&::before {
			content: '+';
			margin-right: .2rem;
		}
	}
	&.negative-sign {		
		color: rgba(255,30,30,0.8);			
		&.positive {
			color: rgba(255,30,30,0.8);			
		}	
		& > .currency-symbol {
			color: rgba(255,30,30,0.8);
		}	
		&::before {
			content: '-';
			margin-right: .2rem;
		}
	}
	&.number-align-right {
		text-align: right;
	}
	& .addon-before {
		margin-right: .2rem;
		color: gray;
	}
	& .addon-after {
		margin-left: .2rem;
		color: gray;
	}
}

/* .ant-select * {
	font-size: .78rem !important; */
/* } */

.ant-modal {
	&.default-modal .ant-modal-content {
		padding-inline: 0px;
		& .ant-modal-header, .ant-modal-body, .ant-modal-footer {
			padding-inline: 1.2rem;
		}
		& .ant-modal-header {
			border-bottom: 1px solid rgba(200,200,200,.7);
			margin-bottom: 1rem;
		}
		& .ant-modal-footer {
			margin-top: 2rem;
			border-top: 1px solid rgba(200,200,200,.7);
			padding-top: 1rem;
		}
	}
}
.ant-collapse {
	&.default-collapse {
		--defaultColor: rgb(4, 34, 136);
		& .ant-collapse-item.default-collapse-panel {
			& .ant-collapse-header {
				border: 2px solid var(--defaultColor);
				border-radius: 5px !important;
				& span.ant-collapse-header-text {
					color: var(--defaultColor);
					font-weight: 600;
				}
				& .ant-collapse-expand-icon {
					& svg {
						color: var(--defaultColor);						
					}
				}
			}
			&:not(.ant-collapse-item-active) {				
				& .ant-collapse-expand-icon {
					& svg {
						transform: rotate(-90deg) !important;					
					}
				}
			}
			& .ant-collapse-content-box {
				padding-block: .75rem;
			}
		}		
	}
}
/* Styles for ant-collapse that is inside FormMainContainer */
.FormMainContainer {
	& .ant-collapse.default-collapse {
		& .ant-collapse-item.default-collapse-panel {				
			&:has(.ant-form-item-has-error,.error-field-info) {
				& .ant-collapse-header {
					border-color: red !important;
				}
				& .ant-collapse-header-text:after {
					/* You must set it in your collapse item or in ant-collapse-header for example style={{"--errorMessage": "Error"}} */
					content: var(--errorMessage);
					color: red;
					font-size: 0.8rem;
					margin-left: 0.4rem;
					animation: blink 3s linear infinite;
				}
				& .ant-collapse-expand-icon svg {
					color: red;
				}
			}
		}
	}
}

main {	
	&:not(.dense) {
		& .page-content-info {
			padding: 1rem;
			padding-bottom: 0px;
		}
		& .page-content-main {
			padding: 1rem;
			padding-top: 0px;
		}
	}
	&:has(.page-content-spinner-container) {
		& .page-content-main {
			filter: blur(4px);
		}
	}
}

.menu-bar-container {
	& .ant-menu-submenu-arrow {
		color: gray !important;
	}
}

.ldx-icons {
	width: 1.15rem;
	height: 1.15rem;
}

.presets-form-button-actions {
	&.horizontal-layout {
		display: flex;
		gap: .5rem;
		padding: .5rem .5rem;
	}
	&.vertical-layout {
		display: flex;
		flex-direction: column;
		gap: .5rem;
		padding: 1rem .5rem;
	}
	border: .13rem dashed rgba(200,200,200,.7);
}

.ant-btn-group.bordered {	
	& button:not(:first-child) {
		border-left: 1px dashed rgba(200,200,200,.7) !important;
	}

	&.solid-border {
		& button:not(:first-child) {
			border-left-style: solid !important;
		}	
	}
}