.container {
  height: 56px;
  border-bottom: 2px solid #013588;
}

.logo-header {
  margin-top: -20px;
}

.avatar-header {
  text-align: end;
  margin-top: -25px;
}

.title-header {
  margin-top: -20px;
  color: #013588;
  text-align: center;
  font-size: 24pt;
}

.notification-container{
  width: 20rem;
  padding: 0px;
  margin-top: 1rem;
  margin-right: 0.75rem;
  .notification-header{
    cursor: default;
    padding: 8px 10px;
    border-bottom: 1px solid #cecece;
  }
  .notification-body{
    max-height: 350px;
    overflow-y: auto;
    .notification-body-list-item-unread{
      padding: 5px 12px;
      cursor: pointer;
      background-color: #fcc9c9;
    }
    .notification-body-list-item{
      padding: 5px 12px;
      cursor: pointer;
    }
    .notification-body-list-item-key{
      width: 100%;
      .notification-body-title-container{
        display: flex;
        justify-content: space-between;
        .notification-body{
          display: flex;
          width: 100%;
          justify-content: space-between;
          .notification-body-title{
            font-weight: bold;
            font-size: 13px;
          }
          .notification-body-time{
            font-size: 11px;
          }
        }
      }
      .notification-body-message{
        font-size: 12px;
      }
    }

    .notification-body-list-item-unread:hover{
      background-color: #ffc1c1;
    }

    .notification-body-list-item:hover{
      background-color: #f7f7f7;
    }
  }

  .notification-body::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgb(192, 192, 192);
    background-color: #F5F5F5;
  }

  .notification-body::-webkit-scrollbar{
    width: 5px;
    background-color: #F5F5F5;
  }

  .notification-body::-webkit-scrollbar-thumb{
    background-color: #013588;
    border: 2px solid #013588;
  }

  .notification-footer{
    cursor: pointer;
    padding: 4px;
    border-top: 1px solid #cecece;
    text-align: center;
  }

  .notification-footer:hover{
    background-color: #f0f0f0;
  }
}

@media (max-width: 900px) {
  .title-header {
    font-size: 20pt;
  }
}

@media (max-width: 700px) {
  .title-header {
    font-size: 17pt;
  }
}

@media (max-width: 600px) {
  .title-header {
    font-size: 15pt;
  }
}

@media (max-width: 550px) {
  .title-header {
    font-size: 12pt;
  }
}

@media (max-width: 480px) {
  .title-header {
    font-size: 10pt;
  }
}

@media (max-width: 420px) {
  .title-header {
    font-size: 8pt;
  }
}

@media (max-width: 380px) {
  .title-header {
    font-size: 5pt;
  }
}
