
.sales-payment-method-config {
  & .amount-left {
    font-weight: 500;
    display: flex;
    &.is-overpay {
      color: green;
      &::before {
        content: '+';
        color: green;
      }
    }
    &:not(.is-overpay) {
      color: red;
      &::before {
        content: '-';
        color: red;
      }
    }
  }
  & .current-summary-info .check-icon svg {
    color: green;
  }
}

.ant-modal.sales-payment-method-config-modal {
  & .ant-table-body {
    & tbody {
      & tr.ant-table-row {
        & td {
          padding-block: .3rem !important;
        }
        &.new-item td {
          background-color: rgb(226, 255, 226);
        }
      }
    }
    & .ant-table-summary {      
      & td {
        font-weight: 500;
        background-color: rgba(200,200,200,.1);
        & span {
          font-weight: bold;
        }
        & .total-remain-amount {
          &.is_overpay {
            & * {
              color: green;
            }
            & > span::before {
              content: '+';
              font-weight: bold;                          
              margin-right: .2rem;
            }
          }
          &:not(.is_payed) {
            & * {
              color: red;
            }
            & > span::before {
              content: '-';
              font-weight: bold;                          
              margin-right: .2rem;
            }
          }
        }
        &:not(:has(.total-remain-amount)) {
          border-bottom: 1px solid rgba(90,90,90,.4);
          padding-block: .7rem;
        }
        &:has(.total-remain-amount:not(.is_payed)) {
          background-color: rgb(243, 138, 138) !important;
          * {
            color: white;
          }
        }
      }
    }
  }
}