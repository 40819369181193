


.filter-container-v2 {    
  & .ant-row {
    margin-bottom: 10px;
  }
  & .expand-btn .anticon {
    transition: all 0.5s ease;
  }
  
  &.expanded {   
    & .expand-btn {    
      & .anticon {
        transform: rotate(180deg) translate(0px, 2px);    
      }
    }
  }  
}