#placeholderSize {
  width: 100%;
  :global {
    .ant-select-selection-item {
      // width: 100px;
      max-width: 100%;
      white-space: normal !important;
    }
  }
}
