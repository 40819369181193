@import 'src/styles/vars';

#container {
  :global {
    .ant-menu-horizontal {
      line-height: 55px;
      background-color: #e7ebf2;
      max-width: $max-width;
      margin: auto;
      color: rgb(45, 112, 227);
    }

    .ant-menu-horizontal:not(.ant-menu-dark)
      > .ant-menu-submenu-selected
      .ant-menu-submenu-title {
      color: #044495 !important;
    }

    .ant-menu-submenu-horizontal,
    .ant-menu-submenu-title {
      color: rgb(45, 112, 227);
    }
  }
}
