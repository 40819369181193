/**
  available variables:
    --header_backgroundColor
    --header_textColor
    --header_paddingInline
    --header_paddingBlock
    --cell_paddingInline
    --cell_paddingBlock
    --scrollbarColor

*/

@keyframes cell-has-form-error-bg-blink {
  0% {
    background-color: rgba(255,160,160,0);    
  }
  80% {
    background-color: rgba(255,160,160,.4);
  }
}

.table-extended-container {
	width: 100%;
	/* overflow: auto; */	

	& .auto-adjustable-cell-width {
		& .ant-table-body table tbody tr.ant-table-row {
			& td {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	& table {		
		& thead {						
			& tr {
				background-color: var(--header_backgroundColor) !important;
				& .draggable-handler {
					cursor: grabbing;
				}
				& th .resizer {
					position: absolute;
					background: transparent;

					&.resizer-r {
						cursor: col-resize;
						height: 100%;
						right: 0;
						top: 0;
						width: 5px;
					}
					
					&.resizer-t {
						cursor: row-resize;
						height: 5px;
						left: 0;
						top: 0;
						width: 100%;
					}
					
					&.resizer-b {
						cursor: row-resize;
						height: 5px;
						left: 0;
						bottom: 0;
						width: 100%;
					}
					
					&.resizer-l {
						cursor: col-resize;
						height: 100%;
						left: 0;
						top: 0;
						width: 5px;
					}
				}

				& th.resizing {
					cursor: col-resize;
					opacity: .5;
				}
			}
			& th,
			td.ant-table-selection-column {
				background-color: var(--header_backgroundColor) !important;
				color: var(--header_textColor) !important;
				padding-block: var(--header_paddingBlock) !important;
			}

			& th.table-ext-column-title {
				padding-block: var(--header_paddingBlock) !important;
				padding-inline: var(--header_paddingInline) !important;
			}

			& th.ant-table-column-has-sorters,
			th.ant-table-column-has-sorters:hover {
				background-color: var(--header_backgroundColor);
			}
			/* show pin column only if left column is pinned */
			/* & th:not(.ant-table-cell-fix-sticky) + th:has(.table-ext-column-pin-btn) {
				& .table-ext-column-pin-btn {
					display: none;
				}
			} */
		}

		& .table-ext-column-header-container {
			& .table-ext-column-header-actions {
				& svg {
					width: 22px;
					height: 22px;
					scale: 0.9;
					cursor: pointer;
					margin: 0px;
					padding: 0px;
				}

				& svg:not(.sort-icon svg):hover {
					scale: 1.1;
					transition: 200ms;
				}

				& span.sort-icon {
					position: absolute;
					left: 0;
					margin-left: auto;
					margin-right: auto;
					scale: 0.8;
					&:first-child {
						top: -.85rem;
					}
					&:last-child {
						bottom: -.85rem;
					}
					& svg {
						width: 18px;
						height: 18px;
					}
				}
				& div:has(span.sort-icon) {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 18px;
					height: min-content;
				}
				& div:has(span.sort-icon).sort-ascend span:first-child svg {
					fill: rgb(59 130 246);					
				}
				& div:has(span.sort-icon).sort-descend span:last-child svg {
					fill: rgb(59 130 246);
				}
			}
		}			
	}

	& .ant-table-container {	
		/* HIDE ALL HORIZONTAL SCROLL, use Custom Scrollbar */
		& .ant-table-body {
			overflow-x: hidden !important;
		}	
		& .ant-table-sticky-scroll {
			display: none;
		}
		/* END */

		& .ant-table-body::-webkit-scrollbar {
			padding-inline: 5px !important;
			color: var(--scrollbarColor) !important;
			height: 0.65rem !important;
			width: 0.65rem !important;
		}

		& .ant-table-body::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3) !important;
			box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3) !important;
			width: 0.2rem !important;
			color: var(--scrollbarColor) !important;
		}

		& .ant-table-body::-webkit-scrollbar-thumb {
			background-color: var(--scrollbarColor) !important;
			border-radius: 2rem !important;
		}

		&:has(.ant-table-sticky-scroll) {
			& .ant-table-body {
				overflow-x: hidden !important;
				&::-webkit-scrollbar-track {
					height: 0 !important;
				}
			}
		}
	}

	& .table-ext-column-title.required::after {
		content: '*';
		margin-left: 5px;
		color: red;
		font-size: 1.135rem;
	}

	& tbody tr.ant-table-placeholder .ant-empty {
		padding: 0 !important;
		margin: 0 !important;
	}	

	& tbody {
		/* handle bug for antd table column fixed positioning when row selection exists nad have fixed left => its work only if table has horizontal scroll */
		& tr.ant-table-row:has(td.ant-table-selection-column.ant-table-cell-fix-left) {
			& td.ant-table-cell-fix-left:nth-child(2) {
				left: 40px !important;
			}
		}

		& tr.ant-table-row {
			&.orm-code-operation:has(.hide-action-column-orm-delete) {
				& td {
					&.is-action-columns,:has(.action-container){
						display: none;
					}
				}
			}
			& td {
				padding-block: var(--cell_paddingBlock) !important;
				padding-inline: var(--cell_paddingInline) !important;
				&:not(.ant-table-selection-column,.is-action-cell,.is-action-columns,:has(.action-container)) {
					min-width: var(--minCellWidth);
				}
				&.is-action-cell,.is-action-columns,:has(.action-container) {
					width: min-content;
				}
				&.minimum-width-cell,.minimum-width-column {
					min-width: unset;
					width: min-content;					
				}
				/* &.ant-table-cell {
					white-space: pre-wrap;
					word-break: break-word;
				} */
				&.is-url-link-cell {
					& a span.text-link {
						&:hover {
							text-decoration: underline;
							text-underline-offset: 5px;
							text-decoration-thickness: 2px;							
							font-weight: 500;
						}
					}
				}
			}		

			& td.cell-format-number,
			td.cell-format-quantity,
			td.cell-format-currency,
			td.cell-format-percent-comma,
			td.cell-format-date,
			td.cell-format-datetime,
			td.cell-format-datetime_essential,
			td.cell-format-ratio_to_percentage,
			td.cell-format-exchange_rate,
			td.cell-format-multiplication_ratio,
			td.cell-format-percentage {
				text-align: right;
				padding-right: 0.8rem !important;
			}

			& td.cell-format-date  {
				min-width: 200px !important;
			}

			& td:not(.editable) {
				&:is(.cell-format-ratio_to_percentage,.cell-format-percentage) {
					&:not(:has(.cell-editable)),&:has(.cell-editable) .cell-editable{						
						&::after {
							content: '%';
							margin-left: .15rem;
							color: gray;
						}
						/* if empty then show nothing (maybe cause value undefined or null) */
						&:empty::after {
							content: '';
						}
					}
					&.is-infinite {
						visibility: hidden;
						&::after {
							content: '-';
							visibility: visible;
						}
					}
				}
			}

			& td.editable.cell-format-ratio_to_percentage, td.cell-format-multiplication_ratio,
			td.editable.cell-format-percentage {
				& .cell-editable::after {
					content: '%';
					margin-left: .15rem;
					color: gray;
				}
			}

			& td.ant-table-cell.editable {
				& .cell-editable:not(.disable-edit) {
					border: 1px solid rgba(180, 180, 180, 0);
					padding-inline: 8px;
					padding-block: 5px;
					cursor: pointer;
					min-height: 2rem;
					& .place-holder-cell-editable {
						color: rgba(32, 32, 32, 0.5);
					}
					&:has(.place-holder-cell-editable) {
						padding-inline: 8px;
					}
				}
				& .cell-editable:not(.disable-edit):hover {
					border: 1px solid rgba(180, 180, 180, 0.4);
					border-radius: 5px;
					/* &:has(.place-holder-cell-editable) {
						padding-inline: 2px;
					} */
				}
				&:has(p.rich-text) {										
					cursor: pointer;
					& p.rich-text {
						max-width: 400px;						
					}
				}
			}

			& td.sort-ascend:not(.sort-style-off),
			td.sort-descend:not(.sort-style-off) {
				background-color: rgba(228, 228, 228, 0.1);
			}
			
			&.orm-code-2 {
				& td {
					background-color: rgba(224, 61, 61, 0.3) !important;
					&:hover {
						background-color: rgba(224, 61, 61, 0.3) !important;
						opacity: 1;
					}
					.ant-input-affix-wrapper {
						background-color: rgba(255, 255, 255, 0.9);
					}
				}
			}

			&:not(.ant-table-row-selected, .orm-code-2) {
				& td.editable:not(:has(.error-field-info)) {
					background-color: rgba(0, 255, 255, .12) !important;
					background-blend-mode: darken !important;    
				}
				& td.editable:has(.error-field-info) {
					background-color: rgba(255,160,160,.4);
					&.use-blink-animation {
						-webkit-animation: cell-has-form-error-bg-blink 800ms infinite;  /* Safari 4+ */
						-moz-animation: cell-has-form-error-bg-blink 800ms infinite;  /* Fx 5+ */
						-o-animation: cell-has-form-error-bg-blink 800ms infinite;  /* Opera 12+ */
						animation: cell-has-form-error-bg-blink 800ms infinite;  /* IE 10+, Fx 29+ */
					}
				}				
			}
			&.ant-table-row-selected {
				& td {
					background-color: rgba(186,224,255,1);				
				}
				&:hover td {
					background-color: rgba(186,224,255,.6);				
				}
			}
		}
	}
	& div:has(.custom-scrollbar-watcher)  {		
		position: -webkit-sticky !important;
  	position: sticky !important;
		bottom: 0;
		z-index: 60;
	}
	& .custom-scrollbar-watcher {
		-webkit-appearance: none;
  	appearance: none;
  	outline: none;

		&::-webkit-slider-runnable-track {
			/* background: gray; */
			box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3) !important;
			border-radius: .5rem;
		}
		&::-webkit-slider-thumb {			
			-webkit-appearance: none; /* Override default look */
			appearance: none;
			
			background-color: var(--scrollbarColor);
			/* background-color: var(--scrollbarColor); */
			height: .48rem;
			width: 150px;
			border-radius: 2rem;
		}
	}
}
.table-ext-setting-modal {
	& .table-ext-setting-column-tab {
		display: flex;
		flex-direction: column;
		gap: 5px;
		& > div {
			padding: .5rem;
			/* background-color: rgba(206, 206, 206, 0.5); */
			border: 2px solid rgba(206, 206, 206, 0.5);
			border-radius: .4rem;
			& span.col-title {
				font-weight: 500;
				font-size: .9rem;
			}
			& .action-icon {
				font-size: 1.2rem;
				cursor: pointer;
				&:hover {
					color: rgb(59 130 246);
					scale: 1.2;
				}
				transition: 200ms scale;
			}
			&:not(.detailed):hover {				
				background-color: aliceblue;
				cursor: pointer;
			}
		}
	}
}

.fullscreen-wrapper {
	background-color: white;
	border: 0px;	
	overflow-y: scroll;
	& .ant-table {
		border-radius: 0px !important;
	}
}