.loading {
  width: 100%;
  height: 100vh;
  text-align: center;
  padding: 50px;
  position: fixed;
  top: 0;
  left: 0;
  background: #f3f3f377;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

.fullContainer {
  position: absolute;
  background-color: var(--antd-color-gray);
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 100;
}

.fullLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
