@import 'antd/dist/antd.min.css';
@import '../v2source/styles/App.css';
@import '../components/Templates/Display/display.template.css';

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

:root {
  --backgroundColor: #f7f7f7;
  --backgroundGray: #f8f8fa;
  --dark-blue: rgb(4, 34, 136);
  --textColorSecondary: rgba(0, 0, 0, 0.45);

  --primaryFont: SFProText-Regular;
  --primary-color: #2d70e3;
  --menu-item-color: #044495;

  --layout-header-height: 40px;
  --border-radius-base: 4px;

  --btn-border-radius-base: 4px;
  --menu-item-color: rgb(4, 34, 136);
  --descriptions-bg: rgb(4, 34, 136);
}

body {
  /* Remove horizontal scroll */
  overflow-x: hidden;
}

#__next {
  height: 100vh;

  .ant-card-bordered {
    border: 2px solid #f0f0f0;
  }
}

.ant-menu,
.ant-menu-item a,
.ant-menu-submenu-title {
   /* give !important will issue with disabled menu color */
  color: #044495;
}

/* .ant-menu {
  & .ant-menu-title-content {
    color: #044495;
  }
} */

.ant-btn {
  border-radius: var(--border-radius-base);
}

.cursor-pointer {
  cursor: pointer !important;
}

.rounded-8 {
  border-radius: 8px !important;
}

.w-full {
  width: 100%;
}

.full-height {
  height: 100%;

  .ant-spin-container {
    height: 100%;
  }
}

.reset-a,
.reset-a:hover,
.reset-a:visited,
.reset-a:focus,
.reset-a:active {
  text-decoration: none;
  color: inherit;
  outline: 0;
}

.penbar {
  padding: 10px 20px;
  border-bottom: 1px solid #ededed;
  border-left: solid #003288 6px;

  .ant-typography {
    color: #003288;
  }
}

.gutler {
  padding-left: 10px;
  padding-right: 10px;
}

.custom-table {
  .rt-tr {
    height: 40px;
  }
  .rt-th {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #013588;
    border-right: 1px solid #ffffff;
    color: #ffffff;
  }
  .rt-td {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ReactTable .rt-tbody .rt-td {
    border-right: 1px solid #ffffff;
  }
}

.custom-table-left {
  .rt-th {
    justify-content: start;
  }
  .rt-td {
    justify-content: start;
  }
}

.custom-table-40 {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .custom-table-40 {
    width: 40%;
  }
}

 /* Override */
.ant-descriptions-view .ant-descriptions-row .ant-descriptions-item-label,
.ant-table .ant-table-thead th.ant-table-cell {
  background: var(--dark-blue);
  color: white;
  & * {
    color: white;
  }  
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #003288 !important;
}

.ant-select-selection-item {
  white-space: normal !important;
}

.filter-container {
  border-left: solid #003288 4px;
  margin: 0 -1rem;
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: none !important;
}

.ant-form-item-label > label.ant-form-item-required::after {
  content: '*' !important;
  position: relative;
  margin: 0 8px 0 4px;
  display: inline-block;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  display: block !important;
}

.ant-select-clear {
  margin-top: -6px !important;
}

.ant-page-header-heading {
  clear: both;
}

.LDXForm .ant-row.ant-form-item label:not(.ant-radio-wrapper) {
  font-size: 14px !important;
  font-weight: normal !important;
}

.LDXForm .ant-checkbox-wrapper {
  color: black !important;
}

.LDXForm .ant-input-custom-error .ant-form-item-explain {
  display: none;
}

.ant-input-custom-error .ant-form-item-explain {
  display: none;
}

.ant-descriptions-bordered .ant-descriptions-row {
  border-right: 1px solid rgb(240, 240, 240);
}

.Container {
  padding: 1rem !important;
}
.Container .Filter {
  padding: 1rem !important;
  border-left: 10px solid var(--dark-blue);
  margin-left: -1rem;
  margin-right: -1rem;
  flex-basis: calc(100% + 2rem);
}
.Container .ButtonContainer {
  padding: 1rem !important;
  margin-left: -1rem;
  margin-right: -1rem;
  flex-basis: calc(100% + 2rem);
  max-width: calc(100% + 2rem);
  background-color: #ededed;
}
.Container .FilterWrapper {
  margin: -1rem;
  border-left: 8px solid var(--dark-blue);
  padding-left: 1rem !important;
  padding-top: 1rem;
}
.Container .FilterWrapper .ant-form-item-label > label {
  color: var(--dark-blue) !important;
}
.Container .BreadCrumbWrapper {
  margin-top: 1rem;
}
.Container .ButtonWrapper {
  background-color: #ededed;
  padding: 1rem !important;
  flex-basis: 102%;
  margin-right: -1rem;
  margin-left: -1rem;
}

.MaxWidth {
  overflow-x: hidden;
  max-width: 100%;
}
.align-right {
  text-align: right;
}

.ant-form-item-no-colon {
  white-space: normal !important;
}

.FieldInput .ant-row.ant-form-item {
  margin-bottom: 0.25rem;
}

.FieldInput .ant-form-item-label.ant-form-item-label-left {
  background-color: #003288;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
}

.FieldInput .ant-row.ant-form-item label {
  color: white;
}

.FieldInput .ant-form-item-control {
  justify-content: center;
  padding: 0.5rem;
  background: #fff;
  border: 1px solid #f0f0f0;
}

.FieldInput .ant-form-item-explain.ant-form-item-explain-error {
  padding-left: 0.2rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.ReactTable .rt-noData {
  z-index: 10 !important;
}

.Skeleton-300 {
  width: 100%;
}

.FilterContainer {
  border-left: 0.25rem solid #003288;
  margin-left: -8px;
  margin-right: 16px;
}
.FilterContainer .ant-form-item {
  margin-bottom: 10px;
}
.FilterContainer .ant-form-item-label.ant-form-item-label-left {
  padding: 0 0.5rem;
}
.FilterContainer .ant-form-item-label > label {
  color: rgb(4, 34, 136);
}

.FullWidth {
  width: 100%;
}
/* 
.LDXForm tr {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
} */

.LDXForm .ant-descriptions-row {
  border-right: none !important;
  display: flex !important;
}

.LDXForm .ant-descriptions-row .ant-descriptions-item-label {
  display: block;
  flex: 0 0 50%;
  position: relative;
  max-width: 50%;
  padding: 13px !important;
}

.LDXForm .ant-descriptions-row .ant-descriptions-item-content {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
}

.LDXForm
  .ant-descriptions-row
  .ant-descriptions-item-content
  > span
  > div:not(#errorFormik) {
  width: 100%;
  max-width: 100%;
}

.LDXForm .ant-descriptions-header {
  background-color: #042288;
  padding: 10px 0;
  margin-bottom: 0;
  text-align: center;
}

.LDXForm .ant-descriptions-title {
  color: #fff;
}

.LDXForm .ant-row.ant-form-item {
  margin-bottom: 0.25rem;
}

.LDXForm .ant-form-item {
  margin-bottom: 4px;
  & .ant-form-item-label.ant-form-item-label-left {
    background-color: #042288;
    & label {
      color: white;
      padding-block: 1.5rem;
      padding-inline: .75rem;
    }
  }
}

.LDXForm .bg-transparent .ant-form-item-label.ant-form-item-label-left {
  background-color: #fff !important;
}

.LDXForm .ant-row.ant-form-item.bg-transparent label:not(.ant-radio-wrapper) {
  color: #003288;
}

.LDXForm .ant-row.ant-form-item label:not(.ant-radio-wrapper) {
  color: white;
  padding: 1.5rem 0.75rem;
  text-align: left;
}

.LDXForm .ant-form-item-control {
  justify-content: center;
  padding: 0.5rem;
  background: #fff;
  border: 1px solid #f0f0f0;
}

.LDXForm .WithBorder .ant-form-item-control-input {
  border: 1px solid #d9d9d9;
}

.LDXForm .ant-radio-group {
  padding-left: 10px;
}
.ant-form-item-explain .ant-form-item-explain-error {
  position: initial !important;
}
.LDXForm .ant-form-item-explain .ant-form-item-explain-error {
  padding-top: 0.2rem;
  position: initial !important;
  font-size: .8rem;
}
.LDXForm .ant-collapse-content-box {
  padding: 12px 0 !important;
}

.LDXFormWrapper .ant-form-item-label > label {
  color: rgb(4, 34, 136);
}

.LDXForm .FullWidth {
  width: 100%;
}

.LDXForm .LDXFormRow .ant-form-item-control .ant-form-item-control {
  border: none;
}

.LDXForm .NoBorder > * {
  border: none !important;
}

.BackgroundGrey {
  background-color: #ededed;
  padding: 10px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}

.BackgroundGreyFull {
  background-color: #ededed;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.bg-grey {
  background-color: #ededed;
}
.section-title {
  max-width: 1440px;
  border-left: 5px solid #003288;
  padding: 7px;
  color: #003288 !important;
  align-items: center;
  flex: auto;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-upload.ant-upload-select.ant-upload-select-text {
  display: block !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  position: initial !important;
  font-size: 12px;
}
.ant-form-item-explain {
  position: initial !important;
  font-size: 12px;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  min-width: 300px;
}
span.ant-radio {
  text-align: left;
}
.ant-radio-wrapper {
  text-align: left;
}

.editable-cell {
  position: relative;
}
.mr-10 {
  margin-right: 10px;
}
.row-custom-bg {
  background: white;
  border: none !important;
}
.row-sum {
  color: black;
  background: white;
}
.row-border-top {
  border-top: 1px solid black;
  color: black;
  background: white;
}
label {
  display: block;
  /* text-align: right; */
}
.text-color {
  color: var(--dark-blue);
}
.breadcrumb-content {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
.breadcrumb-container {
  color: gray;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: solid white 1px;
}
.body-container {
  color: gray;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.body-container-padding {
  padding: 5px 15px;
}
.nav-body {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5px;
}
.body-bar {
  padding: 10px 20px;
  border-left: solid #003288 6px;
}

.title {
  padding: 10px 20px;
  border-left: solid #003288 6px;
  font-size: 20px;
  font-weight: bold;
}

.font {
  font-size: 20px;
  font-weight: bold;
}

.last-row {
  background: white;
  border: none !important;
}

.border-left-blue {
  border-left-width: 4px;
  border-left-color: #003288 !important;
  border-left-style: solid !important;
  border-bottom-style: solid !important;
  border-bottom-color: #f2f2f2 !important;
}
.border-left-blue .ant-form-item-label > label {
  color: rgb(4, 34, 136);
  text-align: left;
}

.ant-form-vertical .ant-form-item-label > label::after {
  display: inline-flex !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.cell-input-number .ant-input-number-input-wrap input {
  text-align: right !important;
}

.cell-date-picker .ant-picker-input input {
  text-align: right !important;
}

.debounce-custom-selector-code-name .ant-select-item.ant-select-item-option {
  border-bottom: 1px solid rgba(210,220,210,.7);
}

.debounce-custom-selector-code-name .ant-select-item.ant-select-item-option:has(.is-selected) {
  background: rgb(230,247,255);
}
.debounce-custom-selector-code-name .ant-select-item.ant-select-item-option:has(.is-selected) span {
  font-weight: 600 !important;
}
.ant-input-number.number-align-right input {
  text-align: right;
}
span.input-number-as-text.number-align-right {
  text-align: right;
}
td.ant-descriptions-item-content:has(span.input-number-as-text) {
  width: 50%;
}
.amount-summary-by-group-popover td.ant-descriptions-item-content {
  display: flex !important;
  align-items: center !important;
}
.amount-summary-by-group-popover {
	min-width: 300px;
	max-width: 800px;
}
.input-number-as-text {
	display: block;
	&.number-align-right {
		text-align: right;
	}
	& .addon-before {
		margin-right: .2rem;
		color: gray;
	}
	& .addon-after {
		margin-left: .2rem;
		color: gray;
	}
  &.negative-amount {
    color: red;
    &::before {
      content: '-';
      margin-right: 2px;
    }
  }
}
/* for dev information element */
#server-env-information {  
  background: rgba(30,30,30,.5);
  opacity: .6;
  color: white;
  border-radius: 2rem;
  margin-left: 10px;
  font-size: 11px;
  font-weight: 500;
  padding: 2px 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}