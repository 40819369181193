@import 'src/styles/breakpoints';
@import 'src/styles/mixins';
@import 'src/styles/vars';

.section {
  max-width: $max-width;
  margin: auto;
  transition: all 0.2s;

  padding: 0 16px;
  @include xs {
    padding: 0 24px;
  }
}
